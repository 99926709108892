<template>
  <div>
    <b-row v-if="showFilters">
      <b-col v-if="showResultsPerPage" md="6">
        <b-input-group>
          <b-form-select
            v-model="selectedValue"
            :options="options"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            @change="onResultsPerPageChange"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col :md="searchColumnSize">
        <b-input-group>
          <b-form-input
            v-model="localSearch"
            @change="onSearchChange"
            id="pesquisa-input"
          />
          <b-input-group-append>
            <b-button variant="primary">
              <i class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table-simple class="mt-2" hover responsive stacked="sm" :class="id">
          <b-thead>
            <b-tr>
              <b-th class="text-center" v-if="buttonsStart">
                <slot name="buttonsCaption"></slot>
              </b-th>
              <b-th
                v-for="field in this.visibleHeaders"
                :key="field.name"
                :aria-sort="sortIcon(field.name)"
                :id="getIdHeader(field.name)"
                class="text-center"
                v-on:click="sort(field.name)"
                >{{ field.caption }}</b-th
              >
              <b-th class="text-center" id="button" v-if="buttonsEnd">
                <slot name="buttonsCaption"></slot>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(row, rowIndex) in this.results"
              v-on:click="onSelectedRow(row, rowIndex)"
              @dblclick="clienteSelecionado()"
              :class="selectedRowKey == rowIndex ? selectedRowClass : ''"
              :key="rowIndex"
              ref="linha"
            >
              <b-td v-if="buttonsStart">
                <slot name="buttons" v-bind:row="row"></slot>
              </b-td>

              <template v-for="(propertyValue, propertyName) in row">
                <b-td
                  :stacked-heading="fieldCaption(propertyName)"
                  v-if="visibleColumn(propertyName)"
                  :key="propertyName"
                  data-toggle="tooltip"
                  :title="propertyValue"
                  :class="classFromValue(propertyValue, propertyName)"
                >
                  <slot
                    :name="propertyName"
                    v-if="hasSlot(propertyName)"
                    v-bind:propertyValue="
                      formatData(propertyValue, propertyName, rowIndex)
                    "
                    v-bind:propertyName="propertyName"
                    v-bind:row="row"
                    >{{ formatData(propertyValue, propertyName, rowIndex) }}
                  </slot>
                  <slot
                    :name="'default'"
                    v-else
                    v-bind:propertyValue="
                      formatData(propertyValue, propertyName, rowIndex)
                    "
                    v-bind:propertyName="propertyName"
                    v-bind:row="row"
                    >{{
                      formatData(propertyValue, propertyName, rowIndex)
                    }}</slot
                  >
                </b-td>
              </template>

              <b-td class="text-center" v-if="buttonsEnd">
                <slot name="buttons" v-bind:row="row"></slot>
              </b-td>
            </b-tr>
            <b-tr v-if="!this.results || this.results.length == 0">
              <b-td class="text-center sem-borda" :colspan="getLenghtColspan()">
                Nenhum resultado encontrado
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="showDadosPaginacao">
        Pagina {{ this.page }} de {{ this.totalPages }}, resultado
        {{ currentResults }} de {{ this.totalResults }}
      </b-col>
      <b-col class="text-center" style="display: ruby">
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="this.totalResults"
            :per-page="this.resultsPerPage"
            class="mt-4"
            first-number
            last-number
            @change="onPageChange"
          >
            <template v-slot:prev-text>
              <span class="text-primary"> Anterior </span>
            </template>
            <template v-slot:next-text>
              <span class="text-primary"> Próxima </span>
            </template>
            <template v-slot:ellipsis-text>
              <span>
                <i class="fa fa-ellipsis-h"></i>
              </span>
            </template>
            <template v-slot:page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "../../assets/css/DataTableRowButton.css";
export default {
  name: "MyDataTable",
  props: {
    id: {
      type: String,
      default: "MyDataTable",
    },
    search: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    sortColumn: {
      type: String,
      default: "id",
    },
    sortDirection: {
      type: String,
      default: "asc",
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    results: {
      type: Object,
      default: null,
    },
    resultsPerPage: {
      type: Number,
      default: 10,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Object,
      default: null,
    },
    buttons: {
      type: String,
      default: "",
    },
    showResultsPerPage: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showDadosPaginacao: {
      type: Boolean,
      default: true,
    },
    selectedRowClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedRowKey: null,
      localSortDirection: "",
      selectedValue: this.resultsPerPage.toString(),
      localSearch: this.search,
      currentPage: this.page,
      options: [
        { item: "10", name: "Mostrar 10 item" },
        { item: "20", name: "Mostrar 20 itens" },
        { item: "30", name: "Mostrar 30 itens" },
        { item: "40", name: "Mostrar 40 itens" },
        { item: "50", name: "Mostrar 50 itens" },
      ],
      searchData: {
        params: {
          search: "",
          page: 1,
          resultsPerPage: this.resultsPerPage,
          sortColumn: null,
          sortDirection: null,
        },
      },
    };
  },
  computed: {
    searchColumnSize() {
      return this.showResultsPerPage ? "6" : "12";
    },

    visibleHeaders() {
      if (this.fields == null) return false;
      return this.fields.filter(
        (field) => !this.hiddenFields.includes(field.name)
      );
    },
    currentResults() {
      if (this.resultsPerPage * this.page > this.totalResults)
        return this.totalResults;
      else return this.resultsPerPage * this.page;
    },
    buttonsStart() {
      return this.$props.buttons === "start";
    },
    buttonsEnd() {
      return this.$props.buttons === "end";
    },
  },
  methods: {
    onSelectedRow(row, key) {
      this.$emit("onSelectedRow", row);
      this.selectedRowKey = key;
    },
    visibleColumn(field) {
      if (field == null) return false;
      return !this.hiddenFields.includes(field);
    },
    getIdHeader(field) {
      return field + this.id;
    },
    onSearchChange() {
      this.searchData.params.search = this.localSearch;
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = "";
      this.searchData.params.sortDirection = "asc";
      this.searchData.params.resultsPerPage = this.resultsPerPage * 1;
      this.$emit("doSearch", this.searchData);
    },
    onResultsPerPageChange() {
      this.searchData.params.search = this.localSearch;
      this.searchData.params.page = 1;
      this.searchData.params.sortColumn = this.sortColumn;
      this.searchData.params.sortDirection = this.sortDirection;
      this.searchData.params.resultsPerPage = this.selectedValue * 1;
      this.resultsPerPage = this.selectedValue * 1;
      this.$emit("doSearch", this.searchData);
    },
    onPageChange(page) {
      this.searchData.params.search = this.localSearch;
      this.searchData.params.page = page;
      this.searchData.params.sortColumn = this.sortColumn;
      this.searchData.params.sortDirection = this.sortDirection;
      this.searchData.params.resultsPerPage = this.resultsPerPage * 1;
      this.$emit("doSearch", this.searchData);
    },
    sort(column) {
      this.searchData.params.search = this.localSearch;
      this.searchData.params.page = this.page;
      this.searchData.params.sortColumn = column;
      this.searchData.params.sortDirection = this.getSortDirection();
      this.searchData.params.resultsPerPage = this.resultsPerPage * 1;
      this.$emit("doSearch", this.searchData);
    },
    sortIcon(column) {
      let sortColumnArray = this.$props.sortColumn.split(".");
      let sortColumnArrayLen = sortColumnArray.length;
      if (
        column ===
        sortColumnArray[sortColumnArrayLen > 1 ? sortColumnArrayLen - 1 : 0]
      ) {
        if (this.$props.sortDirection === "desc") return "descending";
        else return "ascending";
      } else {
        return "none";
      }
    },
    getSortDirection() {
      if (this.localSortDirection === "") {
        this.localSortDirection = "asc";
      } else if (this.$props.sortDirection === "asc") {
        this.localSortDirection = "desc";
      } else {
        this.localSortDirection = "asc";
      }
      return this.localSortDirection;
    },
    fieldCaption(propertyName) {
      if (this.$props.fields == undefined) return "";
      for (let index = 0; index < this.$props.fields.length; index++) {
        const field = this.$props.fields[index];
        if (field.name === propertyName) return field.caption;
      }
      return "";
    },
    hasSlot(name = "default") {
      let has =
        !!this.$slots[name] ||
        !!this.$scopedSlots[name] ||
        this.$scopedSlots.hasOwnProperty(name);
      return has;
    },
    classFromValue(value, propertyName) {
      if (this.id == "clientsTable") {
        if ("codigo".includes(propertyName)) return "column-codigo";
        if ("uf".includes(propertyName)) return "column-uf";
        if ("status".includes(propertyName)) return "column-status";
        if ("razao_social".includes(propertyName)) return "column-razao";
        if ("cliente".includes(propertyName)) return "column-cliente";
        if ("cnpj".includes(propertyName)) return "column-cnpj";
        if ("data_ultima_venda".includes(propertyName)) return "column-data";
        if (["cidade", "ramo_atividade"].includes(propertyName))
          return "column-cidade-ramo";
      } else if (this.id == "budgetsTable" || this.id == "ordersTable") {
        if ("id".includes(propertyName)) return "column-codigo";
        if ("codigo".includes(propertyName)) return "column-codigo";
      }

      if (this.$helper.isNumeric(value)) return "text-right";
      if (this.$helper.isDate(value)) return "text-center";
    },
    formatData(value, propertyName, index) {
      if (propertyName == "cnpj") {
        return value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );
      } else if (this.id == "ordersTable") {
        if (propertyName == "codigo" && this.results[index].codigo_eive) {
          return this.results[index].codigo_eive;
        } else if (
          propertyName == "total" &&
          this.results[index].total_erp > 0
        ) {
          return this.results[index].total_erp;
        }
        return value;
      } else {
        return value;
      }
    },
    clienteSelecionado() {
      this.$emit("clienteSelecionado", this.$event);
    },
    getLenghtColspan() {
      if (!this.visibleHeaders) {
        return 1;
      }
      return this.$props.buttons === "end"
        ? this.visibleHeaders.length + 1
        : this.visibleHeaders.length;
    },
  },
};
</script>

<style>
.clientsTable .table-sm th,
.clientsTable .table-sm td.table-sm th,
.clientsTable .table-sm td {
  padding: 0.3rem 0.5em;
}
.clientsTable
  .table.b-table.table-sm
  > thead
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left),
.clientsTable
  .table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 0 center;
  padding-right: 0;
}
.sem-borda {
  border: none !important;
}
.column-razao {
  width: 19.1em;
  max-width: 19.1em;
}
.column-razao > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.column-cnpj {
  width: 10em;
  max-width: 10em;
}

.column-cnpj > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.column-cidade-ramo {
  width: 13em;
  max-width: 13em;
}
.column-cidade-ramo > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.column-data {
  text-align: center;
  min-width: 3em;
}
.column-status {
  text-align: center;
  min-width: 1em;
}
.column-uf {
  text-align: center;
  min-width: 1em;
}
.column-codigo {
  text-align: center;
  width: 6em;
  max-width: 6em;
}
.table thead th {
  vertical-align: inherit !important;
}

.table thead {
  font-size: 14px;
}
.table tbody {
  font-size: 16px;
}
.table tbody td {
  border-bottom: 1px solid #e6e6e6;
  padding: 0.5rem;
}
.table thead th {
  border-bottom: 1px solid #e6e6e6;
}
.table th,
.table td {
  border-top: 0px;
}
.btn-acoes {
  display: block !important;
}
.page-link {
  color: #5046e5 !important;
}
.page-link i {
  font-style: normal !important;
}
.page-item.active .page-link {
  background-color: #5046e5;
  border-color: #5046e5;
  color: #fff !important;
}
.text-primary {
  color: #5046e5 !important;
}
</style>