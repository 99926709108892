<template>
  <div class="animated fadeIn container novoLayout">
    <b-row>
      <b-col md="12" class="card" style="padding-top: 20px">
        <b-card no-body>
          <b-card-header>
            <span class="col-sm-12 col-md-2"> Grupo de Usuários </span>
          </b-card-header>
          <b-card-body>
            <div class="text-right mb-3">
              <b-button
                class="col-sm-12 col-md-2"
                size="sm"
                v-show="!disabled"
                v-on:click="newGrupo"
                variant="novogrupo"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14"
                  width="14"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="#4038b7"
                    d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"
                  />
                </svg>
                Novo Grupo
              </b-button>
            </div>
            <b-container>
              <my-data-table
                id="gruposTable"
                :search="this.searchResult.search"
                :fields="this.searchResult.fields"
                :showFilters="false"
                :showDadosPaginacao="false"
                :sortColumn="this.searchResult.sortColumn"
                :sortDirection="this.searchResult.sortDirection"
                :totalResults="this.searchResult.totalResults"
                :results="this.searchResult.results"
                :resultsPerPage="this.searchResult.resultsPerPage"
                :totalPages="this.searchResult.totalPages"
                :page="this.searchResult.page"
                :pages="this.searchResult.pages"
                :buttons="'end'"
                class="font-12"
                v-on:doSearch="loadGrupos"
              >
                <template #buttons="value">
                  <div class="text-center btn-acoes">
                    <b-button
                      class="my-data-table-row-button"
                      size="sm"
                      variant="primary"
                      v-on:click="editGrupo(value.row)"
                    >
                      <i class="fa fa-pencil"></i>
                    </b-button>
                  </div>
                </template>
                <template #buttonsCaption>Ações</template>
              </my-data-table>
            </b-container>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GrupoPermissao from "../../../services/grupoPermissao";
import MyDataTable from "@/components/ui/MyDataTableNovo";

export default {
  name: "GrupoPermissao",
  components: { MyDataTable },
  data() {
    return {
      searchResult: new Object(),
      disabled: false,
    };
  },
  mounted() {
    this.validaPermissao();
    this.loadGrupos();
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/configuracao/grupo_permissao").then(
        (response) => {
          if (!response.data.data) this.$router.push("/");
          else this.disabled = response.data.data.edita != "1";
        }
      );
    },
    loadGrupos(data = null) {
      let params = "resultsPerPage=5";
      GrupoPermissao.getGruposPermissao(data, params)
        .then((response) => {
          this.searchResult = response.data.data;
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
          } else {
            this.$helper.showErrorResponse(e);
          }
        });
    },
    newGrupo() {
      this.$router.push("grupo_permissao/grupo/novo");
    },
    editGrupo(row) {
      this.$router.push("grupo_permissao/grupo/" + row.id + "/editar");
    },
  },
};
</script>

<style scoped>
.btn-acoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font-12 {
  font-size: 12px;
}

.novoLayout .card-header {
  background-color: #cfc7f3;
  color: #3634a3;
}

.btn-novogrupo {
  border: 1px solid #938cee;
  color: #938cee;
  border-radius: 6px;
  height: 35px;
}

.my-data-table-row-button {
  background-color: #4038b7;
  border-color: #4038b7;
  border-radius: 50px;
}
</style>

<style>
.table th:first-child {
  width: 60px !important;
}

.table th:last-child {
  width: 100px !important;
}
</style>